import axios from "axios";
import { getToken } from "./getLocalStorageHelper";

export async function axiosPost(url, data, config = {}) {
  const token = getToken();

  try {
    // Setting default headers if not provided
    const defaultConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    // Merge the defaultConfig with any additional config passed in
    const finalConfig = {
      ...defaultConfig,
      headers: { ...defaultConfig.headers, ...config.headers },
    };
    // console.log("Headers sent:", finalConfig.headers);

    // Make the POST request
    const response = await axios.post(url, data, finalConfig);
    // console.log("AxiosPost 1 Response: ", response.status);

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle errors (e.g., network issues, server errors)
    handleAxiosError(error);
  }
}

export async function axiosPostTest(url, data, config = {}) {
  const token = getToken();

  try {
    // Setting default headers if not provided
    const defaultConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    // Merge the defaultConfig with any additional config passed in
    const finalConfig = {
      ...defaultConfig,
      headers: { ...defaultConfig.headers, ...config.headers },
    };

    // Make the POST request
    const response = await axios.post(url, data, finalConfig);
    // console.log("AxiosPostTest Response Data:", response.data);
    // console.log("AxiosPostTest Response Status:", response.status);

    // Return the response data and status
    return { status: response.status, data: response.data };
  } catch (error) {
    // Log the error response details for debugging
    if (error.response) {
      // console.error("Error Status:", error.response.status);
      // console.error("Error Data:", error.response.data);
      return { status: error.response.status, data: error.response.data };
    } else {
      // console.error("Network or unexpected error:", error.message);
      throw new Error("Network or unexpected error occurred");
    }
  }
}

export async function axiosPut(url, data, config = {}) {
  const token = getToken();

  try {
    // Setting default headers if not provided
    const defaultConfig = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    // Merge the defaultConfig with any additional config passed in
    const finalConfig = {
      ...defaultConfig,
      headers: { ...defaultConfig.headers, ...config.headers },
    };

    // Make the PUT request
    const response = await axios.put(url, data, finalConfig);

    // Return the response data
    return { status: response.status, data: response.data };
  } catch (error) {
    // Handle errors (e.g., network issues, server errors)
    handleAxiosError(error);
  }
}

export async function axiosGet(url, config = {}, data = {}) {
  const token = getToken();
  console.log("url: ", url);
  console.log("data: ", data);
  try {
    // Setting default headers if not provided
    const defaultConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    // Merge the defaultConfig with any additional config passed in
    const finalConfig = {
      ...defaultConfig,
      headers: { ...defaultConfig.headers, ...config.headers },
    };

    // Make the GET request
    const response = await axios.get(url, finalConfig, data);

    // Return the response data
    return response.data;
  } catch (error) {
    // Handle errors (e.g., network issues, server errors)
    handleAxiosError(error);
  }
}

function handleAxiosError(error) {
  if (error.response) {
    // Server responded with a status code outside the 2xx range
    console.error("Server Error:", error.response);
    throw { status: error.response.status, data: error.response.data };
  } else if (error.request) {
    // Request was made but no response was received
    console.error("Network Error:", error.request);
    throw new Error("Network error, please try again later.");
  } else {
    // Something happened in setting up the request
    console.error("Error:", error.message);
    throw new Error("An unexpected error occurred.");
  }
}
