export const getToken = () => localStorage.getItem("token");
export const getBusinessID = () => localStorage.getItem("businessID");
export const getProfileImageID = () => localStorage.getItem("profileImageID");
export const getProfileImageURL = () => localStorage.getItem("profileImageURL");
export const getBusinessName = () => localStorage.getItem("businessName");
export const getUserEmail = () => localStorage.getItem("emailAddress");
export const getUid = () => localStorage.getItem("uid");

export const getUser = () => {
  const user = localStorage.getItem("user");
  if (user === "" || !user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

// Retrieve user details from localStorage
export const getFullName = () => {
  try {
    const jsonString = localStorage.getItem("user");
    if (!jsonString) return null;

    const userDetails = JSON.parse(jsonString);
    if (typeof userDetails !== "object" || !userDetails) return null;

    const { firstName, lastName } = userDetails;
    return `${firstName || ""} ${lastName || ""}`;
  } catch (error) {
    return "";
  }
};

export const getUserManagementColumnPreferences = () => {
  const preferences = localStorage.getItem("UserManagementUserTable-columns");
  return preferences ? JSON.parse(preferences) : [];
};

export const getUserManagementStatusColumns = () => {
  const preferences = localStorage.getItem("UserManagementStatusTable-columns");
  return preferences ? JSON.parse(preferences) : [];
};

export const getAppBusinessSettingsUserManagementStatuses = () => {
  const preferences = localStorage.getItem(
    "appBusinessSettings-UserStatusColumns"
  );
  return preferences ? JSON.parse(preferences) : [];
};

export const getAppBusinessSettingsUserManagementRolesTable = () => {
  const preferences = localStorage.getItem("UserManagementRolesTable-columns");
  return preferences ? JSON.parse(preferences) : [];
};
