import React, { useState } from "react";
import { setUserSession } from "../../helpers/setLocalStorageHelper";
import { useNavigate } from "react-router-dom";

import Button from "../../components/app/reusable/buttons/Button";
import { axiosPost } from "../../helpers/axiosHelper";

import "../../css/login-registration.css"; // Import the CSS file
import "../../css/button.css";
import "../../css/forms.css";
import {
  getBusinessID,
  getUid,
  getUserEmail,
} from "../../helpers/getLocalStorageHelper";

const getBusinessConfigurationsUrl =
  process.env.REACT_APP_GET_APP_BUSINESS_SETTINGS;

export default function Auth() {
  const loginAPIUrl = process.env.REACT_APP_LOGIN_API_URL;
  const totpLoginUrl = process.env.REACT_APP_TOTP_LOGIN_API_URL;
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [totpCode, setTotpCode] = useState("");
  const [requireTotp, setRequireTotp] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showRegistration, setShowRegistration] = useState(true);

  let navigate = useNavigate();

  const submitHandler = (event) => {
    event.preventDefault();
    if (emailAddress.trim() === "" || password.trim() === "") {
      setErrorMessage("Both username and password are required");
      return;
    }
    setErrorMessage(null);

    const requestBody = {
      currentEmailAddress: emailAddress.trim(),
      password: password.trim(),
    };

    axiosPost(loginAPIUrl, requestBody)
      .then((response) => {
        console.log("response: ", response);
        // Check if TOTP is required
        if (response.requireTotp) {
          setRequireTotp(true);
          setShowRegistration(false);
        } else if (
          response.uid &&
          response.businessId &&
          response.businessName &&
          response.emailAddress &&
          response.user &&
          response.token &&
          response.profileImageID
        ) {
          setUserSession(
            response.uid,
            response.businessId,
            response.businessName,
            response.emailAddress,
            response.user,
            response.token,
            response.profileImageID
          );
          navigate("/app");
          downloadSettingsToLocalStorage(); // Call the function here
        } else {
          setErrorMessage("Invalid login response");
        }
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage("Sorry, the backend server is down");
        }
      });
  };

  const submitTotpHandler = (event) => {
    event.preventDefault();

    if (totpCode.trim() === "") {
      setErrorMessage("2FA code is required");
      return;
    }
    setErrorMessage(null);

    const requestBody = {
      totpCode: totpCode.trim(),
      currentEmailAddress: emailAddress.trim(),
    };

    axiosPost(totpLoginUrl, requestBody)
      .then((response) => {
        console.log("response: ", response);
        if (
          response.uid &&
          response.businessId &&
          response.businessName &&
          response.emailAddress &&
          response.user &&
          response.token &&
          response.profileImageID
        ) {
          setUserSession(
            response.uid,
            response.businessId,
            response.businessName,
            response.emailAddress,
            response.user,
            response.token,
            response.profileImageID
          );
          navigate("/app");
          downloadSettingsToLocalStorage(); // Call the function here
        } else {
          setErrorMessage("Invalid 2FA response");
        }
      })
      .catch((error) => {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage("Sorry, the backend server is down");
        }
      });
  };

  const handleCancel = () => {
    setEmailAddress("");
    setPassword("");
    setShowPassword(false);
    setTotpCode("");
    setRequireTotp(false);
    setErrorMessage(null);
    setShowRegistration(true);
  };

  const navigateToRegister = () => {
    navigate("/register");
  };

  return (
    <>
      <div className="auth-container">
        {!requireTotp ? (
          <>
            <div className="login-box">
              <form onSubmit={submitHandler}>
                <h2>Login</h2>
                <div className="form-group left-content">
                  <label>Email:</label>
                  <input
                    type="text"
                    value={emailAddress}
                    onChange={(event) => setEmailAddress(event.target.value)}
                  />

                  <label>Password:</label>
                  <input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <div className="right-content">
                    <div id="showPassword">
                      <input
                        type="checkbox"
                        checked={showPassword}
                        onChange={() => setShowPassword(!showPassword)}
                      />
                      <label>Show Password</label>
                    </div>
                  </div>
                  <div>
                    <Button type="submit" className="submit-button">
                      Login
                    </Button>
                  </div>
                  {errorMessage && (
                    <p className="message error">{errorMessage}</p>
                  )}
                </div>
              </form>
              <div>
                <a href="">Forgot password?</a>
              </div>
            </div>
            {showRegistration && (
              <>
                <div className="or-divider">
                  <span>or</span>
                </div>
                <div className="signup-box">
                  <h2>Sign up</h2>
                  <p>Click below to create an account and join us.</p>
                  <Button
                    className="submit-button"
                    onClick={navigateToRegister}
                  >
                    Register
                  </Button>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            <div className="signup-box">
              <form onSubmit={submitTotpHandler} autoComplete="off">
                <h5>Enter 2FA Code</h5>
                <div className="form-group">
                  <label>2FA Code:</label>
                  <input
                    type="text"
                    name="totpCode"
                    value={totpCode}
                    onChange={(event) => setTotpCode(event.target.value)}
                    autoComplete="off"
                  />
                </div>
                <div className="button-group">
                  <Button type="submit" className="submit-button">
                    Verify 2FA Code
                  </Button>
                  <Button
                    type="button"
                    className="cancel-button"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </div>
                {errorMessage && (
                  <p className="message error">{errorMessage}</p>
                )}
              </form>
            </div>
          </>
        )}
      </div>
      <div className="auth">
        <p>
          This website is secure and your personal details are protected. For
          more information, view our Terms & Conditions and our Security,
          Privacy & Cookie Policy.
        </p>
      </div>
    </>
  );
}

// Function to download user and business settings from the server and stores them in local storage.

const downloadSettingsToLocalStorage = async () => {
  const currentEmailAddress = getUserEmail();

  try {
    const params = {
      currentEmailAddress,
      filter: {
        userUid: getUid(),
        businessId: getBusinessID(),
      },
    };

    const getConfigurationsResponse = await axiosPost(
      getBusinessConfigurationsUrl,
      params
    );
    console.log("getConfigurationsResponse: ", getConfigurationsResponse);

    getConfigurationsResponse.forEach((item) => {
      const { configName, configType, config } = item;
      const localStorageKey = `${configName}-${configType}`;
      localStorage.setItem(localStorageKey, JSON.stringify(config));
    });
  } catch (error) {}
};
